<template>
  <div class="container">
    <div class="text-center" style="padding: 30px 0">
      <img src="/assets/img/404.png" class="img-fluid" alt="" />
      <p>This page could not be found.</p>
      <a
        class="btn btn-theme"
        :href="$router.resolve({ name: 'redirect' }).href"
        >Back To Home</a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

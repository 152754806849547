<template>
  <div>
    <h4>Pesapal</h4>
    <div v-if="! loading" v-html="iframe"></div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      iframe: '',
      loading: true
    }
  },

  props: ['account'],

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/payments/${this.$route.params.id}/pesapal`)
        .then((response) => {
          this.iframe = response.data;
          this.loading = false;
        });
    },
  }
};
</script>

<template>
  <div>
    <div id="main-wrapper">
      <app-header />

      <div class="clearfix"></div>

      <section class="gray pt-4" v-if="!loading && !has_error">
        <div class="container">
          <h3>Payment</h3>
          <div class="row">
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <template v-if="content.type == 'property'">
                    <h4 class="text-muted">Promote</h4>
                    <h5>{{ content.title }}</h5>
                    <div class="pricing-wrap platinum-pr">
                      <div class="pricing-header">
                        <h4 class="pr-value"><sup>KES</sup>{{ content.pricing.amount }}</h4>
                        <h4 class="pr-title">{{ content.pricing.title }}</h4>
                      </div>
                      <div class="pricing-body">
                        <table class="table table-bordered">
                          <tbody>
                            <tr v-for="(value, key) in content.pricing.content" :key="`content-${key}`">
                              <td>{{ key }}</td>
                              <td>{{ value }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </template>
                  <template v-if="content.type == 'field-assistant'">
                    <h4>Field Assistant Request</h4>
                    <h6>Pay KES {{ account.amount }}</h6>
                    <h6 class="text-muted">Details</h6>
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{{ content.name }}</td>
                        </tr>
                        <tr>
                          <td>Moving To</td>
                          <td>{{ content.region }}</td>
                        </tr>
                        <tr>
                          <td>Moving Date</td>
                          <td>{{ content.date }}</td>
                        </tr>
                        <tr>
                          <td>Requested On</td>
                          <td>{{ content.created }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="car">
                <div class="card-bod">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pesapal-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#pesapal"
                        type="button"
                        role="tab"
                        aria-controls="pesapal"
                        aria-selected="false"
                      >
                        PesaPal
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content bg-white" id="myTabContent">
                    <div
                      class="tab-pane fade"
                      id="pesapal"
                      role="tabpanel"
                      aria-labelledby="pesapal-tab"
                    >
                      <pesapal-payment :account="account" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <loading v-if="loading" />

      <error v-if="has_error" />

      <app-footer />
    </div>
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppFooter from "../components/AppFooter.vue";
import PesapalPayment from "@/components/payments/Pesapal.vue";
import Error from "@/components/404.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
    PesapalPayment,
    Error,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      loading: true,
      account: {},
      content: {},
      has_error: false,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$axios
        .get(`/api/v1/payment/${this.$route.params.id}`)
        .then((response) => {
          this.account = response.data.account;
          this.content = response.data.content;
          this.loading = false;
        })
        .catch(() => {
          this.has_error = true;
          this.loading = false;
        });
    },
  },
};
</script>
